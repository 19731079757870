<script>
	import { onMount } from "svelte";
	import ArtistLogo from "../components/ArtistLogo.svelte";
	import CanvasContainer from "../components/CanvasContainer.svelte";
	import ColorToolBar from "../components/ColorToolBar.svelte";
	import Container from "../components/Container.svelte";
	import GameHeader from "../components/GameHeader.svelte";
	import InputBox from "../components/InputBox.svelte";
	import { dbGameSessionRound, listenOnFirebaseKey } from "../services/database";
	import { artist, myUserStore, winner } from "../services/store";
	import Winner from "../components/Winner.svelte";
	let word;
	onMount(() => {
		listenOnFirebaseKey(dbGameSessionRound().child("wordChoosen"), _word => {
			word = _word;
		});
	});
</script>

<GameHeader />

<div class="container">
	<CanvasContainer theArtist={$artist?.user}>
		{#if $winner}
			{#if $winner.id == $myUserStore?.id || $artist?.id == $myUserStore?.id}
				<h1 class="heading">Congratulations</h1>
			{:else}
				<h1 class="heading">Better luck next time!</h1>
			{/if}
		{:else}
			<h1 class="heading">It’s not you, It’s them!</h1>
		{/if}
		<div class="correct-word--container">
			<div class="correct-word--headingContainer">
				<span class="correct-word__heading">The Correct Word</span>
			</div>
			<div class="words--container">
				{#each (word || "").split("") as char}
					<span class="char">{char}</span>
				{/each}
			</div>
			{#if !$winner && $myUserStore?.id != $artist?.id}
				<div class="artistLogo">
					<ArtistLogo theArtistImage={$artist?.profilePicture} theArtist={$artist?.userName} />
				</div>
			{/if}
		</div>
		{#if $winner}
			<div class="winners--container">
				<Winner
					profilePicture={$artist?.profilePicture}
					heading="The Artist"
					headingSvg="/svg/paint-brush-small.svg"
					userName={$artist?.userName}
				/>
				<Winner
					profilePicture={$winner?.profilePicture}
					heading="The Guesser"
					headingSvg="/svg/thinking.svg"
					userName={$winner?.userName}
				/>
			</div>
		{/if}
	</CanvasContainer>
	<div class="container__bottom">
		{#if $myUserStore?.id == $artist?.id}
			<ColorToolBar />
			{#if $winner}
				<Container center={true} class="word">Well Drawn</Container>
			{:else}
				<Container center={true} class="word">You tried</Container>
			{/if}
		{:else}
			{#if $winner && $winner?.id == $myUserStore?.id}
				<Container center={true} class="word">Well Thought</Container>
			{:else}
				<Container center={true} class="word">You tried</Container>
			{/if}
			<InputBox disabled={true} />
		{/if}
	</div>
</div>

<style>
	.container {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 65%;
		align-items: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
	.heading {
		color: #f6963b;
		text-align: center;
		font-family: Pally-Bold;
		font-size: 24px;
		line-height: 32px;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
	}
	.correct-word--headingContainer {
		transform: translate(0, -50%);
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0;
	}
	.correct-word__heading {
		background-color: #f6963b;
		color: black;
		padding: 4px 16px;
		border-radius: 24px;
	}
	.correct-word--container {
		position: relative;
		background: #f7f7f7;
		margin: 0 auto;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		border-radius: 24px;
		margin-top: 50px;
		padding: 26px;
		width: 70%;
	}
	.char {
		display: inline-block;
		color: black;
		border-bottom: 4px solid black;
		font-size: 24px;
		text-align: center;
		min-width: 22px;
		margin: 0 8px;
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 10px;
	}
	.artistLogo {
		position: absolute;
		bottom: 0;
		transform: translate(0, 50%);
	}
	.winners--container {
		width: 80%;
		margin: 8px auto;
		display: flex;
		justify-content: space-evenly;
	}
</style>
