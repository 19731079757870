import { writable, derived } from "svelte/store";
import { user } from "../services/database";
export const usersStore = writable([]);
export const myUserStore = derived(usersStore, $usersStore => {
	return Object.values($usersStore).find(u => u.id === user.id);
});
export const otherUsersStore = writable([]);
export const notification = writable();

export const chat = writable();
export const didIGuess = writable(false);
export const winner = writable();
export const artist = writable();
export const sound = writable(true);
export const currentTheme = writable();
export const showModal = writable(false);
export const disconnectModalStore = writable(null); 

export const hostId = writable();
chat.subscribe(_ => {
	setTimeout(() => {
		chat.set();
	}, 1000);
});

export const chooseWordTimer = writable(null);
