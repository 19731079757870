<script>
	import { onDestroy } from "svelte";

	import Chats from "../components/Chats.svelte";
	import GameHeader from "../components/GameHeader.svelte";
	import { FAST_COUNTDOWN_START, FAST_COUNTDOWN_STOP } from "../constants/soundConstants";
	import { dbGameSessionRound, listenOnFirebaseKey } from "../services/database";
	import { playSound } from "../services/sounds";
	import { myUserStore, artist } from "../services/store";
	import ArtistScreen from "./ArtistScreen.svelte";
	import GuesserScreen from "./GuesserScreen.svelte";

	let time,
		timerId,
		timerSoundStarted = false;
	listenOnFirebaseKey(dbGameSessionRound().child("expiryAt"), val => {
		function startTime() {
			if (val) {
				time = (val - Date.now()) / 1000;
				timerId = requestAnimationFrame(startTime);
			}
		}
		startTime();
	});
	onDestroy(() => {
		playSound(FAST_COUNTDOWN_STOP);
		return () => cancelAnimationFrame(timerId);
	});
	$: if (!timerSoundStarted && time < 4) {
		playSound(FAST_COUNTDOWN_START);
	}
</script>

<GameHeader time={Math.ceil(time)} />

{#if $artist}
	<div class="container">
		<Chats />
		{#if $myUserStore?.id == $artist?.id}
			<ArtistScreen artist={$artist} />
		{:else}
			<GuesserScreen artist={$artist} />
		{/if}
	</div>
{/if}

<style>
	.container {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 65%;
		align-items: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}

	@media only screen and (max-height: 700px) {
		.container {
			top: 125px;
			transform: translate(-50%, 0);
		}
	}
	@media only screen and (max-height: 600px) {
		.container {
			min-height: 350px;
		}
	}
</style>
