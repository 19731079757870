export class Canvas {
	constructor() {}
	setCanvas(canvasRef) {
		if (canvasRef) {
			this._canvas = canvasRef;
			this._ctx = canvasRef.getContext("2d");
		}
	}

	_drawBackupLines() {
		if (this._linesBackup) this.drawLines(this._linesBackup);
	}
	setWidth(width) {
		if (this._canvas) this._canvas.width = this._width = width;
		this._drawBackupLines();
	}
	setHeight(height) {
		if (this._canvas) this._canvas.height = this._height = height;
		this._drawBackupLines();
	}

	drawLines(lines) {
		if (!this._ctx) return;
		this._linesBackup = lines;
		this.clearCanvas();
		lines.forEach(line => {
			switch (line.instructionType) {
				case "DRAW_LINE":
					this.drawLine(line.points, { color: line.color, lineWidth: line.lineWidth });
					break;
				case "CLEAR":
					this.clearCanvas();
				default:
					break;
			}
		});
	}
	drawLine(points, styles = { color: "Black", lineWidth: 4 }) {
		if (!this._ctx) return;
		if (points) {
			this._ctx.beginPath();
			this._ctx.strokeStyle = styles.color;
			this._ctx.lineWidth = styles.lineWidth;
			this._ctx.lineCap = "round";
			const n = points.length;
			if (n >= 1) this._ctx.moveTo(points[0].x * this._width, points[0].y * this._height);
			for (let i = 1; i < n; i++) {
				this._ctx.lineTo(points[i].x * this._width, points[i].y * this._height);
			}
			this._ctx.stroke();
		}
	}

	clearCanvas() {
		if (!this._ctx) return;
		this._ctx.fillStyle = "white";
		this._ctx.fillRect(0, 0, this._width, this._height);
	}
	setDimension(width, height) {
		if (!this._ctx) return;
		this.setWidth(width);
		this.setHeight(height);
	}
	isCanvas() {
		return !!this._canvas;
	}
}
export const canvasObj = new Canvas();
