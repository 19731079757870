<script>
	import { ProfilePicture } from "da-components";
	export let theArtistImage;
	export let theArtist;
</script>

<div class="info">
	<span class="info__block">
		<ProfilePicture src={theArtistImage} userName={theArtist} size={24} />
	</span>
	<span class="info__block">{theArtist}</span>
	<img class="info__block" src="/svg/paint-brush-small.svg" alt="Paint Brush" />
</div>

<style>
	.info {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		height: 29.92px;
		background: #f8f8f8;
		border-radius: 234234px;
		color: rgba(0, 0, 0, 0.5);
		font-family: Pally-Bold;
		align-items: center;
		text-align: center;
		padding: 0 5px;
	}
	.info__block {
		margin: 0 4px;
	}
</style>
