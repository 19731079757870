import { writable } from "svelte/store";
import { dbLines } from "./database";
import { nullLine } from "../utils/Line";

const DELETE = "DELETE";
const CLEAR = "CLEAR";
export const ERASER = "ERASER";
export const HIGHLIGHTER = "HIGHLIGHTER";

export const colors = [
	"#000000",
	"#C1C1C1",
	"#666666",
	"#EF120C",
	"#740B07",
	"#FF7002",
	"#C23801",
	"#FFE400",
	"#E8A200",
	"#00CC00",
	"#005511",
	"#00B2FF",
	"#00569E",
	"#231FD3",
	"#182377",
	"#D37CAA",
	"#A301BA",
	"#A301BA",
	"#A0522D",
	"#63300D",
];

export const entitySizes = [
	{ text: "1pt", size: 4, styles: "width: 10px; height: 10px;" },
	{ text: "4pt", size: 20, styles: "width: 15px; height: 15px;" },
];

export const entities = [
	{ name: HIGHLIGHTER, shortcut: "D", svgPath: "/svg/highlighter.svg" },
	{ name: ERASER, shortcut: "E", svgPath: "/svg/eraser.svg" },
	{ name: CLEAR, shortcut: "Z", svgPath: "/svg/clear.svg" },
	{ name: DELETE, shortcut: "U", svgPath: "/svg/trash.svg" },
];

export const toolbar = writable({
	color: colors[0],
	entitySize: entitySizes[0],
	entity: entities[0],
});

export const updateColor = color => {
	toolbar.update(prev => ({
		...prev,
		color,
		entity: entities[0],
	}));
};

export const updateSize = entitySize => {
	toolbar.update(prev => ({
		...prev,
		entitySize,
	}));
};

export const updateEntity = entity => {
	toolbar.update(prev => ({
		...prev,
		entity,
	}));
};
