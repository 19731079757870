<script>
	import { ProfilePicture } from "da-components";

	export let profilePicture;
	export let userName;
	export let heading;
	export let headingSvg;
</script>

<div>
	<h1 class="winner__heading">
		{heading}
		<img src={headingSvg} alt="Heading Svg" />
	</h1>
	<div class="winner--details">
		<div class="winner--details__profilePicture">
			<ProfilePicture src={profilePicture} {userName} size={70} />
		</div>
		<div class="winner--details__metadata">
			<h2 class="winner--details__username">{userName}</h2>
			<div class="points">
				<img src="/svg/bubble.svg" alt="Bubble" />
				<span>+40</span>
			</div>
		</div>
	</div>
</div>

<style>
	.winner__heading {
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
		font-size: 24px;
		font-family: Pally-Bold;
	}
	.winner--details {
		display: flex;
		justify-content: space-between;
		color: #3f3f3f;
		font-family: Pally-Bold;
		margin-top: 16px;
	}
	.winner--details__metadata {
		margin-left: 16px;
	}
	.winner--details__username {
		margin: 8px 4px;
	}
	.points {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.winner--details__profilePicture {
		background-color: #f6963b;
		border-radius: 50%;
		padding: 4px;
	}
</style>
