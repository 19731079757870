<script>
	import { dbCountdownTimer, dbTheme, listenOnFirebaseKey } from "../services/database";
	import { TimerScreen, SplashScreen } from "da-components/v0";
	import { currentTheme } from "../services/store";
	let countDown;

	listenOnFirebaseKey(dbCountdownTimer, val => {
		countDown = val;
	});
</script>

{#if countDown !== undefined && countDown > 0}
	<div class="container">
		<TimerScreen
			{countDown}
			displayCounter={true}
			ribbonText={$currentTheme && `${$currentTheme?.themeName} ${$currentTheme?.themeEmoji}`}
		/>
	</div>
{/if}

<style>
	.container {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
