<script>
	import GameHeader from "../components/GameHeader.svelte";
	import { dbGameSessionRound, listenOnFirebaseKey } from "../services/database";
	import { onDestroy, onMount } from "svelte";
	import { chooseWordTimer, myUserStore, usersStore } from "../services/store";
	import ArtistChooseWord from "../components/ArtistChooseWord.svelte";
	import NonArtistChooseWord from "../components/NonArtistChooseWord.svelte";

	let artist;
	let wordChoosen = "";
	function handleClick(word) {
		if (!wordChoosen) {
			(async () => {
				await dbGameSessionRound().child("chooseWordTimer").remove();
				await dbGameSessionRound().child("wordChoosen").set(word);
			})();
			wordChoosen = word;
		}
	}
	onMount(() => {
		listenOnFirebaseKey(dbGameSessionRound().child("chooseWordTimer"), val => {
			if (val) chooseWordTimer.set(val);
			if (val === 1) setTimeout(() => chooseWordTimer.set(null), 1000);
		});
		dbGameSessionRound()
			.child("artist")
			.on("value", snap => {
				if (snap.exists()) {
					artist = $usersStore[snap.val()];
				}
			});
	});

	onDestroy(() => {
		chooseWordTimer.set(null);
	});
</script>

<GameHeader />
<div class="protectCls">
	{#if $myUserStore?.id == artist?.id}
		<ArtistChooseWord {wordChoosen} {handleClick} class="outerContainer" />
	{:else if artist}
		<NonArtistChooseWord {artist} class="outerContainer" />
	{/if}
</div>

<style>
	.protectCls :global(.outerContainer) {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 65%;
		align-items: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		/* margin-top: 110px; */
	}
	@media only screen and (max-height: 700px) {
		.protectCls :global(.outerContainer) {
			top: 125px;
			transform: translate(-50%, 0);
		}
	}
	@media only screen and (max-height: 600px) {
		.protectCls :global(.outerContainer) {
			min-height: 350px;
		}
	}
</style>
