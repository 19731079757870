
import { derived, writable, get } from "svelte/store";
import { dbHost, listenOnFirebaseKey, user } from "./database";
import { usersStore } from "./store";
import { isHostParam } from "./utils"; 
import { getHostInfo } from "da-components/v0";

const gameHostId = writable("");

const isGameHost = derived(gameHostId, $gameHostId => {
	if ($gameHostId === "") return isHostParam();
	return $gameHostId === user.id;
});

listenOnFirebaseKey(dbHost, val => {
	gameHostId.set(val);
});

export const { allHostIds, amIHost } = getHostInfo(isGameHost, gameHostId, usersStore, user); 