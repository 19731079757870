<script>
	import { dbUsersPlayed, listenOnFirebaseKey } from "../services/database";

	import { usersStore } from "../services/store";

	import CanvasSvg from "../svg/CanvasSvg.svelte";
	import CanvasHeader from "./CanvasHeader.svelte";
	export let theArtist;
	let countPlayed = 0;
	listenOnFirebaseKey(dbUsersPlayed, val => {
		if (val) countPlayed = val.length;
	});
	$: totalPlayers = Object.keys($usersStore).length;
	export let theArtistImage;
	export let isCanvas = false;
	let canvasHeight, canvasWidth;
</script>

<div class="canvas--container__outer">
	<img src="/svg/clipper.svg" class="clipper" alt="Clipper" />
	<CanvasSvg />
	<div class="canvas--container__inner">
		<CanvasHeader {theArtist} {countPlayed} {totalPlayers} {theArtistImage} />
		{#if isCanvas}
			<div class="canvasScreen" bind:clientHeight={canvasHeight} bind:clientWidth={canvasWidth}>
				<slot {canvasHeight} {canvasWidth} />
			</div>
		{:else}
			<slot />
		{/if}
	</div>
</div>

<style>
	.canvas--container__outer {
		position: relative;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
	}
	.canvasScreen {
		width: 90%;
		height: 90%;
	}
	.canvas--container__inner {
		width: 95%;
		height: 90%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.clipper {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -60%);
	}
</style>
