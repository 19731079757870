<script>
	import Canvas from "../components/Canvas.svelte";
	import CanvasContainer from "../components/CanvasContainer.svelte";
	import ColorToolBar from "../components/ColorToolBar.svelte";
	import Container from "../components/Container.svelte";
	import { dbGameSessionRound, listenOnFirebaseKey } from "../services/database";
	export let artist;
	export let word = "";
	listenOnFirebaseKey(dbGameSessionRound().child("wordChoosen"), _word => {
		word = _word;
	});
</script>

<CanvasContainer theArtist={artist["userName"]} let:canvasWidth let:canvasHeight isCanvas={true}>
	<Canvas {canvasWidth} {canvasHeight} canDraw={true} />
</CanvasContainer>
<div class="container__bottom">
	<ColorToolBar />
	<Container center={true} class="word">
		<span class="draw">
			<img class="info__block" src="/svg/paint-brush-small.svg" alt="Paint Brush" /> Draw:
		</span>
		<span>
			{word}
		</span>
	</Container>
</div>

<style>
	.container__bottom :global(.colorToolBar) {
		max-width: 450px;
		min-width: 361px;
	}
	.container__bottom :global(.word) {
		flex: inherit;
		font-family: Pally-Bold;
		color: #7979f7;
	}
	.container__bottom :global(.inputBox) {
		display: flex;
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 10px;
	}
	.draw {
		display: flex;
		justify-content: center;
		align-items: center;
		color: grey;
		margin-right: 8px;
	}
</style>
