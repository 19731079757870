<script>
	import { Header } from "da-components/v0";
	import { amIHost } from "../services/hostStore";
	import { playSound } from "../services/sounds";
	import { currentTheme, showModal, sound } from "../services/store";
	let isSoundOn = false;

	$: soundState = $sound;

	$: if (soundState === false) {
		playSound("MUTE");
		isSoundOn = false;
	} else {
		isSoundOn = true;
		playSound("UNMUTE");
	}

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound("CLICK");
		} else if (isSoundOn === false) {
			playSound("CLICK");
			isSoundOn = false;
			sound.update(() => false);
		}
	};
</script>

<div class="container" id="header-container">
	<Header
		gameNameSvg="./svg/logo.svg"
		isHost={$amIHost}
		{soundButtonHandler}
		{isSoundOn}
		onMouseDown={() => playSound("CLICK")}
		themeSupported={true}
		theme={$currentTheme}
		showSettings={true}
		endRoundonClick={() => {
			showModal.set(true);
		}}
	/>
</div>

<style>
	#header-container {
		position: relative;
		z-index: 3;
	}
</style>
