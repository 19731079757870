<script>
	export let center = false;
	export let maxWidth;
	export let background;
	let styleText = "";
	$: {
		styleText = maxWidth ? `max-width: ${maxWidth};` : "";
		styleText = styleText + background ? `background: ${background};` : "";
	}
</script>

<div class="container {$$props.class || ''}" class:makeCenter={center} style={styleText}>
	<slot />
</div>

<style>
	.container {
		/* flex: 1; */
		background: #ffffff;
		border: 2px solid #7979f7;
		box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		font-family: Pally-Bold;
		font-size: 20px;
		padding: 8px 20px;
		filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.15));
		color: #7979f7;
	}
	.makeCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
