<script>
	const DELETE = "DELETE";
	const ERASER = "ERASER";
	const CLEAR = "CLEAR";
	const HIGHLIGHTER = "HIGHLIGHTER";

	import { onDestroy, onMount } from "svelte";
	import { dbLines } from "../services/database";

	import { colors, entitySizes, entities, updateColor, updateEntity, updateSize, toolbar } from "../services/toolbar";
	import Container from "./Container.svelte";

	$: colorActive = $toolbar.color;
	$: entitySizeActive = $toolbar.entitySize;
	$: entityActive = $toolbar.entity;
	let unsubscribe;

	const handleKeyDown = event => {
		entities.forEach(entity => {
			if (event.key.toUpperCase() === entity.shortcut) {
				updateEntity(entity);
			}
		});
	};
	onMount(() => {
		window.addEventListener("keydown", handleKeyDown);
		unsubscribe = toolbar.subscribe(({ entity, color }) => {
			const dbLinesRef = dbLines();
			switch (entity.name) {
				case DELETE:
					dbLinesRef.once("value", snap => {
						const lines = snap.val();
						dbLinesRef.update({
							[lines.length]: {
								instructionType: "CLEAR",
							},
						});
					});
					updateEntity(entities[0]);
					break;
				case CLEAR:
					dbLinesRef.once("value", snap => {
						const lines = snap.val();
						dbLinesRef.set(lines.slice(0, lines.length - 1));
					});
					updateEntity(entities[0]);
					break;

				case ERASER:
					if (color !== "white") {
						toolbar.update(prev => ({
							...prev,
							color: "white",
							entitySize: entitySizes[entitySizes.length - 1],
						}));
					}
					break;
				case HIGHLIGHTER:
					break;
				default:
					break;
			}
		});
	});
	onDestroy(() => {
		window.removeEventListener("keydown", handleKeyDown);
		unsubscribe();
	});
</script>

{#if colors && entitySizes && entities}
	<Container class={$$props.class || ""}>
		<div class="colorToolBar--container">
			{#each entitySizes as _es}
				<div class="entitySize">
					<!-- This div is required for centering the dot using flex-->
					<div class="entitySize--dotContainer">
						<div
							class="entitySize--dotContainer__dot"
							on:click={() => updateSize(_es)}
							style="{_es.styles} {_es == entitySizeActive ? 'background-color: black;' : ''}"
						/>
					</div>
					<span class="entitySize__text">{_es.text}</span>
				</div>
			{/each}
			<div class="colors">
				{#each colors as color}
					<div
						on:click={() => updateColor(color)}
						class="colors--color"
						class:colors--color__active={color === colorActive}
						style="background-color: {color}; {color == colorActive ? `outline-color: ${color} ` : ''};"
					/>
				{/each}
			</div>
			<div class="entities">
				{#each entities as entity, idx}
					<div class="entities--entity" class:entities--entity__active={entity === entityActive}>
						<img src={entity.svgPath} alt={entity.name} on:click={() => updateEntity(entity)} />
						<span>{entity.shortcut}</span>
					</div>
				{/each}
			</div>
		</div>
	</Container>
{/if}

<style>
	/* Container  */
	.colorToolBar--container {
		display: flex;
		align-items: center;
		background: #ffffff;
		border-radius: 8px;
		min-width: 400px;
	}
	/* Entity Sizes */
	.entitySize {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		width: 30px;
		height: 35.31px;
	}
	.entitySize--dotContainer {
		flex: 0.75;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.entitySize--dotContainer__dot {
		border-radius: 50%;
		background: #e7e7e7;
	}
	.entitySize__text {
		flex: 0.25;
		font-family: Pally-Medium;
		font-size: 8px;
		line-height: 11px;
		color: rgba(122, 122, 122, 0.5);
	}

	/* Colors  */
	.colors {
		display: grid;
		grid-template: repeat(2, 18px) / repeat(auto-fill, 18px);
		grid-gap: 4px;
		flex: 1;
		height: 40.58px;
	}
	.colors--color {
		cursor: pointer;
		border-radius: 1.76431px;
		aspect-ratio: 1/1;
	}
	.colors--color__active {
		outline: 1px solid transparent;
		outline-offset: 0.85px;
	}
	/* Entities */
	.entities {
		display: flex;
		flex-direction: row;
	}
	.entities--entity {
		width: 29.58px;
		height: 41.81px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.entities--entity img {
		cursor: pointer;
		width: 26.81px;
		background: #f3f3f3;
		border: 0.693045px solid #bdbdbd;
		border-radius: 2.77218px;
		box-shadow: 0px 0.693045px 0px rgba(0, 0, 0, 0.15);
	}
	.entities--entity__active img {
		background: #d8d8d8;
	}
	.entities--entity span {
		font-style: normal;
		font-weight: 500;
		font-size: 8px;
		line-height: 11px;
		font-family: Pally-Bold;
		color: rgba(122, 122, 122, 0.5);
	}
</style>
