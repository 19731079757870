<script>
	import CanvasContainer from "./CanvasContainer.svelte";
	import ColorToolBar from "./ColorToolBar.svelte";
	import Container from "./Container.svelte";
	import ProgressChooseWord from "./ProgressChooseWord.svelte";
	import Word from "./Word.svelte";
	import { dbGameSessionRound, listenOnFirebaseKey } from "../services/database";
	let words = [];
	export let wordChoosen = "";
	export let handleClick = () => {};
	listenOnFirebaseKey(dbGameSessionRound().child("wordsBasket"), _words => {
		if (_words) {
			dbGameSessionRound().child("wordsBasket").off();
			words = _words;
		}
	});
</script>

<div class={$$props.class}>
	<CanvasContainer>
		<div class="mainContainer">
			<h1 class="artist__heading">
				<img src="/svg/paint-brush-big.svg" alt="Paint Brush" />
			</h1>
			<h1 class="artist__heading">Pick a word to draw!</h1>
			<div class="words">
				{#each words as word}
					<Word
						{word}
						isActive={wordChoosen == word}
						hoverEffect={wordChoosen ? false : true}
						handleClick={() => {
							handleClick(word);
						}}
					/>
				{/each}
			</div>
			<div class="artist--progressContainer">
				<ProgressChooseWord />
				<h6 class="choosing__text">Hurry up or you will get skipped</h6>
			</div>
		</div>
	</CanvasContainer>
	<div class="container__bottom">
		<ColorToolBar />
		<Container center={true} maxWidth="150px">
			<div>Get Ready</div>
		</Container>
	</div>
</div>

<style>
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 20px;
	}

	.choosing__text {
		font-family: Pally-Regular;
		font-size: 14px;
		line-height: 19px;
		color: rgba(0, 0, 0, 0.5);
		text-align: center;
	}
	.artist__heading {
		text-align: center;
		color: #f6963c;
		font-size: 24px;
		font-family: Pally-Bold;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
	}
	.words {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		gap: 5px;
		margin: 20px 0px;
	}
	.artist--progressContainer {
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translate(-50%);
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>
