import { getGameSessionId } from "./utils";

function callPostApi(url, body) {
	return fetch(url, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	});
}

export async function getSimilarRatio(message, roundValue) {
	const rawResponse = await callPostApi("/getSimilarRatio", {
		roomIdSessionId: getGameSessionId(),
		message,
		roundValue,
	});
	const content = await rawResponse.json();
	return content;
}
