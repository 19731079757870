<script lang="ts">
	import Page from "./pages/index.svelte";
	import Notifications from "./components/Notifications.svelte";
	import { user } from "./services/database";
	import { onDestroy, onMount } from "svelte";
	import { usersStore, otherUsersStore, myUserStore, didIGuess, winner, artist } from "./services/store";
	import { isEqual } from "./services/utils";
	import Bubble from "./components/Bubble.svelte";
	import { playSound } from "./services/sounds";
	import { CORRECT_ANSWER, MUTE } from "./constants/soundConstants";

	let oldOtherUserList = [];

	$: users = Object.values($usersStore);
	onMount(() => {
		const intervalId = setInterval(() => {
			const allOtherUsers = users.filter(u => u.id !== user.id);
			const otherUserList = [];
			allOtherUsers.forEach(user => {
				if (user.online === true) {
					otherUserList.push({ ...user, online: true });
				} else if (typeof user.online === "number") {
					if (Date.now() - user.online > 5000) {
						otherUserList.push({ ...user, online: false });
					} else {
						otherUserList.push({ ...user, online: true });
					}
				} else {
					otherUserList.push({ ...user, online: false });
				}
			});
			if (!isEqual(otherUserList, oldOtherUserList)) {
				otherUsersStore.set(otherUserList);
				oldOtherUserList = otherUserList;
			}
		}, 1000);
		return () => clearInterval(intervalId);
	});
	onDestroy(() => {
		playSound(MUTE);
	});
	$: if ($winner && ($didIGuess || $artist?.id == $myUserStore?.id)) {
		playSound(CORRECT_ANSWER);
	}
</script>

<main>
	<Page />
	<Notifications />
	{#if $winner && ($didIGuess || $artist?.id == $myUserStore?.id)}
		<Bubble />
	{/if}
</main>

<style>
	:global(body, html) {
		color: white;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		background-color: var(--base);
	}
	main {
		width: 100vw;
		height: 100vh;
		padding: 1rem;
		background: url(/svg/texture.svg);
		background-size: cover;
		background-repeat: no-repeat;
	}
	:root {
		--base: hsla(240, 85%, 64%);
		--base-l-25: hsl(240, 85%, 71%);
		--base-l-60: hsl(240, 85%, 84%);
		--base-d-60: hsl(240, 85%, 24%);
		font-family: Pally-Regular;
	}
	:global(*) {
		box-sizing: border-box;
	}
</style>
