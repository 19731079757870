import * as Sentry from "@sentry/browser";
import App from "./App.svelte";
if (!process.env.EMULATE && (process.env.APP_ENV === "prod" || process.env.APP_ENV === "dev")) {
    console.log("sentry connected");
    Sentry.init({
        dsn: "https://f9294f103e594b6d8dea2799e1824e81@o422923.ingest.sentry.io/5883847",
        environment: process.env.APP_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
    props: {},
});
export default app;
