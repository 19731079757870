<script>
	import { onMount } from "svelte";

	import CanvasContainer from "../components/CanvasContainer.svelte";
	import Container from "../components/Container.svelte";
	import GameHeader from "../components/GameHeader.svelte";
	import InputBox from "../components/InputBox.svelte";
	import { artist, myUserStore } from "../services/store";
</script>

<GameHeader />
<div class="container">
	<CanvasContainer>
		<h1 class="turn__skipped">Turn Skipped for {$artist?.id === $myUserStore?.id ? "You" : $artist?.userName}</h1>
	</CanvasContainer>
	<div class="container__bottom">
		<Container class="textStyles">
			<span>Don’t forget the theme ;)</span>
		</Container>
		<InputBox class="flexCls" disabled={true} />
	</div>
</div>

<style>
	.container {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 80%;
		height: 65%;
		align-items: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	@media only screen and (max-height: 700px) {
		.container {
			top: 125px;
			transform: translate(-50%, 0);
		}
	}
	@media only screen and (max-height: 600px) {
		.container {
			min-height: 350px;
		}
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 20px;
	}
	.container__bottom :global(.flexCls) {
		display: flex;
		/* flex: 1; */
	}
	.container__bottom :global(.textStyles) {
		display: flex;
		align-items: center;
	}
	.turn__skipped {
		font-size: 50px;
		position: absolute;
		color: #9090be;
		display: flex;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-family: "Pally-Bold";
		text-align: center;
	}
</style>
