<script>
	import { tweened } from "svelte/motion";
	import { linear } from "svelte/easing";
	import { chooseWordTimer } from "../services/store";
	import { onDestroy } from "svelte";

	let time = null;
	$: if ($chooseWordTimer && time == null) {
		time = $chooseWordTimer;
	}
	$: progress = time
		? tweened(1, {
				duration: time * 1000,
				easing: linear,
		  })
		: null;
	$: if (progress) {
		progress.set(0);
	}
	$: val = progress === null ? 1 : $progress;
</script>

<progress class="progress" value={val} />

<style>
	.progress {
		width: 60%;
	}
</style>
