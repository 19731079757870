<script>
	import { onMount } from "svelte";

	import Container from "./Container.svelte";
	export let placeholder = "Enter text!";
	export let disabled = false;
	export let autoFocus = false;
	let element;
	let value = "";
	onMount(() => {
		if (autoFocus && element) element.focus();
	});
	export let handleKeyPress = () => {};
</script>

<Container class={$$props.class}>
	<div class="inputBox">
		{#if disabled}
			<img class="inputBox__pencil" src="/svg/pencil-light.svg" alt="Pencil Light" />
		{:else}
			<img class="inputBox__pencil" src="/svg/pencil-dark.svg" alt="Pencil Dark" />
		{/if}
		<input
			bind:this={element}
			class="inputBox__input"
			{placeholder}
			type="text"
			{disabled}
			bind:value
			on:keypress={e => {
				if (handleKeyPress(e, value)) value = "";
			}}
		/>
	</div>
</Container>

<style>
	.inputBox {
		display: flex;
		align-items: center;
	}
	.inputBox__pencil {
		max-width: 40px;
		padding-left: 15px;
	}
	.inputBox__input {
		flex: 1;
		border: none;
		outline: none;
		margin: 0;
		width: 100%;
	}
</style>
