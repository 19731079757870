<script>
	import ArtistLogo from "./ArtistLogo.svelte";

	export let theArtist;
	export let countPlayed = 0;
	export let totalPlayers = 10;
	export let theArtistImage;
</script>

<div class="canvas--container__header">
	<div class="info">
		<span class="hand info__block"> ✍️ </span>
		<span class="info__block">({countPlayed}/{totalPlayers})</span>
	</div>
	{#if theArtist}
		<ArtistLogo {theArtistImage} {theArtist} />
	{/if}
</div>

<style>
	.canvas--container__header {
		display: flex;
		justify-content: space-between;
		padding: 0 5% 0 2.5%;
	}
	.info {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		height: 29.92px;
		background: #f8f8f8;
		border-radius: 234234px;
		color: rgba(0, 0, 0, 0.5);
		font-family: Pally-Bold;
		align-items: center;
		text-align: center;
		padding: 0 5px;
	}
	.info__block {
		margin: 0 4px;
	}
	.hand {
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: center;
	}
</style>
