import pointGenerator from "./Point";

const Point = pointGenerator(100, 100);

export class Line {
	constructor(_color, _lineWidth, _points = []) {
		this.color = _color;
		this.lineWidth = _lineWidth;
		this.points = _points;
	}
}

export const nullLine = new Line("Black", 1, [new Point(0, 0)]);
