<script>
	import { onDestroy, onMount } from "svelte";
	import { dbLines } from "../services/database";
	import { winner } from "../services/store";
	import { ERASER, HIGHLIGHTER, toolbar, updateColor } from "../services/toolbar";
	import { canvasObj } from "../utils/Canvas";
	import { Line, nullLine } from "../utils/Line";
	import pointGenerator from "../utils/Point";

	export let canvasWidth;
	export let canvasHeight;
	export let canDraw = false;

	let canvas,
		lines = [],
		points = [],
		isDrawing = false;

	$: lineWidth = $toolbar.entitySize;
	$: color = $toolbar.color;
	$: Point = pointGenerator(canvasWidth, canvasHeight);
	$: entity = $toolbar.entity;
	$: canvasObj.setCanvas(canvas);
	$: canvasObj.setHeight(canvasHeight);
	$: canvasObj.setWidth(canvasWidth);
	$: canvasObj.drawLines(lines);

	const dbLinesRef = dbLines();

	onMount(() => {
		dbLinesRef.on("value", snap => {
			if (snap.exists()) {
				lines = snap.val();
			} else {
				dbLinesRef.set({ 0: nullLine });
			}
		});
		return () => dbLinesRef.off("value");
	});
	function throttle(callback, delay) {
		let previousCall = new Date().getTime();
		return function () {
			const time = new Date().getTime();

			if (time - previousCall >= delay) {
				previousCall = time;
				callback.apply(null, arguments);
			}
		};
	}
	function startDrawing(e) {
		isDrawing = true;
		points = [new Point(e.offsetX, e.offsetY)];
		dbLinesRef.update({
			[lines.length]: new Line(color, lineWidth.size, points),
		});
	}
	function draw(e) {
		if (isDrawing && !$winner) {
			points = [...points, new Point(e.offsetX, e.offsetY)];
			dbLinesRef.update({
				[lines.length - 1]: {
					...new Line(color, lineWidth.size, points),
					instructionType: "DRAW_LINE",
				},
			});
		}
	}

	function finishDrawing(e) {
		if (isDrawing) {
			isDrawing = false;
		}
	}
	function getUrl(color, size = 20) {
		const dotCanvas = document.createElement("canvas");

		dotCanvas.width = size;
		dotCanvas.height = size;
		const ctx = dotCanvas.getContext("2d");
		ctx.beginPath();
		ctx.fillStyle = color;
		ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
		ctx.fill();

		return dotCanvas.toDataURL();
	}
	function onMouseEnter() {
		if (entity.name === HIGHLIGHTER) {
			const url = getUrl(color, lineWidth.size);
			canvas.style.cursor = `url(${url}), pointer`;
		}
	}
	function onMouseLeave() {
		canvas.style.cursor = `pointer`;
	}
	$: {
		if (canDraw && canvas) {
			canvas.addEventListener("mouseenter", onMouseEnter);
			canvas.addEventListener("mouseleave", onMouseLeave);
		}
	}
	onDestroy(() => {
		canvas.removeEventListener("mouseenter", onMouseEnter);
		canvas.removeEventListener("mouseleave", onMouseEnter);
	});
</script>

{#if canDraw}
	<canvas
		bind:this={canvas}
		on:mousedown={startDrawing}
		on:mousemove={throttle(draw, 25)}
		on:mouseup={finishDrawing}
		on:mouseleave={finishDrawing}
		class="canvas"
	/>
{:else}
	<canvas bind:this={canvas} class="canvas" />
{/if}

<style>
	.canvas {
		width: 100%;
		height: 95%;
		border: 0.5px solid grey;
		border-radius: 8px;
		margin-top: 10px;
	}
</style>
