<script>
	import { GameHeader } from "da-components/v0";
	import { CLICK, MUTE, UNMUTE } from "../constants/soundConstants";
	import { dbScoreRef, dbTheme, listenOnFirebaseKey } from "../services/database";
	import { playSound } from "../services/sounds";
	import { chooseWordTimer, currentTheme, myUserStore, showModal, sound } from "../services/store";
	import { fancyTimeFormat } from "../utils/fancyTimeFormat";
	import { amIHost } from "../services/hostStore"

	export let time = 60;
	let isSoundOn = true;
	let score = 0;

	$: soundState = $sound;
	$: if (soundState === false) {
		isSoundOn = false;
		playSound(MUTE);
	} else {
		isSoundOn = true;
		playSound(UNMUTE);
	}
	const getTime = time => {
		if (time !== undefined && time >= 0) {
			return fancyTimeFormat(Math.min(time, 180));
		} else if (time <= 0) {
			return "00:00";
		} else return "00:60";
	};

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound(CLICK);
		} else if (isSoundOn === false) {
			playSound(CLICK);
			isSoundOn = false;
			sound.update(() => false);
		}
	};
	listenOnFirebaseKey(dbScoreRef, value => {
		score = value[$myUserStore?.id];
	});

	$: {
		if ($chooseWordTimer) {
			time = $chooseWordTimer;
		}
	}
</script>

<div class="container" id="header--container">
	<GameHeader
		noOfCoins={score}
		{isSoundOn}
		time={getTime(time)}
		gameNameSvg="/svg/logo.svg"
		{soundButtonHandler}
		theme={$currentTheme}
		isHost={$amIHost}
		endRoundonClick={() => {
			showModal.set(true);
		}}
	/>
</div>

<style>
	#header--container {
		position: relative;
		z-index: 3;
	}
</style>
