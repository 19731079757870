<script>
	import CanvasContainer from "../components/CanvasContainer.svelte";
	import Container from "../components/Container.svelte";
	import InputBox from "../components/InputBox.svelte";
	import { ProfilePicture } from "da-components";
	import ProgressChooseWord from "../components/ProgressChooseWord.svelte";
	export let artist;
</script>

<div class={$$props.class}>
	<CanvasContainer>
		<div class="mainContainer">
			<h1 class="artist__heading">
				The Artist
				<img src="/svg/paint-brush-small.svg" alt="Paint Brush" />
			</h1>
			<div class="artist--container">
				<div class="avatar__boundary">
					<ProfilePicture src={artist["profilePicture"]} userName={artist["userName"]} size={110} />
				</div>
				<h1 class="artist__name">{artist["userName"]}</h1>
				<h6 class="choosing__text">is choosing a word to draw</h6>
				<ProgressChooseWord />
			</div>
		</div>
	</CanvasContainer>
	<div class="container__bottom">
		<Container class="textStyles">
			<span>Don’t forget the theme ;)</span>
		</Container>
		<InputBox class="flexCls" disabled={true} />
	</div>
</div>

<style>
	.mainContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.container__bottom :global(.flexCls) {
		display: flex;
	}
	.container__bottom :global(.textStyles) {
		display: flex;
		align-items: center;
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 20px;
	}
	.artist--container {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 50px;
	}
	.artist__name {
		font-family: Pally-Bold;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: #000000;
	}
	.choosing__text {
		font-family: Pally-Regular;
		font-size: 14px;
		line-height: 19px;
		color: rgba(0, 0, 0, 0.5);
		text-align: center;
	}
	.artist__heading {
		text-align: center;
		color: #f6963c;
		font-size: 24px;
		font-family: Pally-Bold;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
	}
	.avatar__boundary {
		width: 117px;
		height: 117px;
		border: 4px solid #f7963c;
		border-radius: 247.175px;
	}
</style>
