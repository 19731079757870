<script>
	import { fly } from "svelte/transition";
	import Canvas from "../components/Canvas.svelte";
	import CanvasContainer from "../components/CanvasContainer.svelte";
	import Container from "../components/Container.svelte";
	import InputBox from "../components/InputBox.svelte";
	import { CLOSE_TO_ANSWER, CORRECT_ANSWER, WRONG_ANSWER } from "../constants/soundConstants";
	import { getSimilarRatio } from "../services/api";
	import { listenOnFirebaseKey, dbEncryptedWord, dbChats, getRoundValue, userId } from "../services/database";
	import { playSound } from "../services/sounds";
	import { didIGuess, winner } from "../services/store";

	export let artist;
	let encryptedWord;
	let closeCall = false;

	$: wordsCount = encryptedWord ? encryptedWord.split(" ").length : 0;
	$: characterPerWordCount = encryptedWord ? encryptedWord.split(" ").map(word => word.length) : [];
	listenOnFirebaseKey(dbEncryptedWord(), _encryptedWord => {
		encryptedWord = _encryptedWord;
	});

	function handleKeyPress(e, val) {
		const message = val.trim();
		const round = getRoundValue();
		if (e.key == "Enter") {
			const chatData = {
				userId,
				message,
				round,
				timestamp: { ".sv": "timestamp" },
			};
			(async () => {
				const { similarRatio } = await getSimilarRatio(message, round);
				if (similarRatio >= 0.7 && similarRatio !== 1) {
					closeCall = true;
					setTimeout(() => {
						closeCall = false;
					}, 1000);
				}
				if (similarRatio < 0.7) playSound(WRONG_ANSWER);
			})();
			dbChats.push(chatData);
			return true;
		}
		return false;
	}
	$: closeCall && playSound(CLOSE_TO_ANSWER);
</script>

<CanvasContainer theArtist={artist["userName"]} let:canvasWidth let:canvasHeight isCanvas={true}>
	<Canvas {canvasWidth} {canvasHeight} />
</CanvasContainer>
<div class="container__bottom">
	{#if encryptedWord}
		<Container center={true} background={$winner ? ($didIGuess ? "#E7F3E2" : "#F7EFE7") : ""}>
			{#if closeCall}
				<span class="word__length" in:fly={{ y: 100, duration: 500 }}> You are close </span>
			{:else if $didIGuess}
				<span class="word__length winnerCall" in:fly={{ y: 100, duration: 500 }}> You got it right </span>
			{:else if $winner}
				<span class="word__length winner" in:fly={{ y: 100, duration: 500 }}>
					{$winner["userName"]} guessed it
				</span>
			{:else}
				<span class="word__length" in:fly={{ y: -100, duration: 500 }}>
					{wordsCount} word
				</span>
				{#each characterPerWordCount as count}
					<span class="character__count" in:fly={{ y: -100, duration: 500 }}>{count}</span>
				{/each}
			{/if}
		</Container>
	{/if}
	<InputBox autoFocus={true} class="inputBox" {handleKeyPress} disabled={!!$winner} placeholder="Enter your guess!" />
</div>

<style>
	.container__bottom :global(.colorToolBar) {
		/* flex: 3; */
		max-width: 450px;
		min-width: 361px;
	}
	.container__bottom :global(.inputBox) {
		/* flex: 1; */
		display: flex;
	}
	.container__bottom {
		display: flex;
		justify-content: center;
		width: 98%;
		gap: 10px;
	}
	.character__count {
		background-color: #7979f7;
		color: white;
		width: 25px;
		height: 25px;
		border-radius: 25px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 2px;
	}
	.word__length {
		color: #7979f7;
		font-family: Pally-Medium;
		font-size: 17.8925px;
	}
	.winnerCall {
		color: #44ce13;
	}
	.winner {
		color: #f6963b;
	}
</style>
