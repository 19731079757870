import { get } from "svelte/store";
import {
	BGMUSIC_START,
	BGMUSIC_STOP,
	CLAPPINGS_START,
	CLAPPINGS_STOP,
	CLICK,
	CLOSE_TO_ANSWER,
	CORRECT_ANSWER,
	COUNTDOWN_START,
	COUNTDOWN_STOP,
	FAST_COUNTDOWN_START,
	FAST_COUNTDOWN_STOP,
	MUTE,
	PROMPT_TO_CANVAS,
	QUESTION_TO_LEADERBOARD,
	SKIPPED,
	TIMER_TO_CANVAS_START,
	TIMER_TO_CANVAS_STOP,
	UNMUTE,
	WRONG_ANSWER,
} from "../constants/soundConstants.js";

import { sound } from "./store";

const bgMusic = new Audio("/sounds/bgMusic.mp3");
const clappings = new Audio("/sounds/clappings.mp3");
const clickSound = new Audio("/sounds/click.wav");
const correctAnswer = new Audio("/sounds/correctAnswer.mp3");
const wrongAnswer = new Audio("/sounds/wrongAnswer.mp3");
const closeToAnswer = new Audio("/sounds/closeToAnswer.wav");
const timerToCanvas = new Audio("/sounds/timerToCanvas.wav");
const promptToCanvas = new Audio("/sounds/promptToCanvas.wav");
const questionToLeaderboard = new Audio("/sounds/questionToLeaderboard.wav");
const fastTimer = new Audio("/sounds/fastTimer.mp3");
const countdown = new Audio("/sounds/countdown.wav");
const skipped = new Audio("/sounds/skipped.wav");

bgMusic.loop = true;

export const playSound = type => {
	let value = get(sound);
	switch (type) {
		case BGMUSIC_START:
			play(bgMusic, 0.1, value, false);
			break;
		case BGMUSIC_STOP:
			stop(bgMusic);
			break;
		case CLICK:
			play(clickSound, 0.2, value);
			break;
		case CORRECT_ANSWER:
			play(correctAnswer, 0.1, value);
			break;
		case WRONG_ANSWER:
			play(wrongAnswer, 0.1, value);
			break;
		case CLAPPINGS_START:
			play(clappings, 0.01, value);
			break;
		case CLOSE_TO_ANSWER:
			play(closeToAnswer, 0.1, value);
			break;
		case TIMER_TO_CANVAS_START:
			play(timerToCanvas, 0.2, value, false);
			break;
		case TIMER_TO_CANVAS_STOP:
			stop(timerToCanvas);
			break;
		case COUNTDOWN_START:
			play(countdown, 0.2, value, false);
			break;
		case COUNTDOWN_STOP:
			stop(countdown);
			break;
		case FAST_COUNTDOWN_START:
			play(fastTimer, 0.2, value, false);
			break;
		case FAST_COUNTDOWN_STOP:
			stop(fastTimer);
			break;
		case PROMPT_TO_CANVAS:
			play(promptToCanvas, 0.2, value);
			break;
		case QUESTION_TO_LEADERBOARD:
			play(questionToLeaderboard, 0.75, value);
			break;
		case CLAPPINGS_STOP:
			stop(clappings);
			break;
		case SKIPPED:
			play(skipped, 0.2, value, false);
			break;
		case MUTE:
			bgMusic.volume = 0;
			clappings.volume = 0;
			clickSound.volume = 0;
			correctAnswer.volume = 0;
			wrongAnswer.volume = 0;
			closeToAnswer.volume = 0;
			timerToCanvas.volume = 0;
			promptToCanvas.volume = 0;
			questionToLeaderboard.volume = 0;
			fastTimer.volume = 0;
			countdown.volume = 0;
			skipped.volume = 0;
			break;
		case UNMUTE:
			bgMusic.volume = 0.1;
			clappings.volume = 0.1;
			clickSound.volume = 0.1;
			correctAnswer.volume = 0.1;
			wrongAnswer.volume = 0.1;
			closeToAnswer.volume = 0.1;
			timerToCanvas.volume = 0.1;
			promptToCanvas.volume = 0.1;
			questionToLeaderboard.volume = 0.1;
			fastTimer.volume = 0.1;
			countdown.volume = 0.1;
			skipped.volume = 0.1;
			break;
		default:
			break;
	}
};

const play = async (sound, volume, value, refresh = true) => {
	try {
		if (refresh) {
			await sound.pause();
			sound.currentTime = 0;
		}
		sound.volume = value ? volume : 0;
		await sound.play();
	} catch (error) {
		console.log(error, "Browser is preventing autoplay of music");
	}
};

const stop = async sound => {
	try {
		await sound.pause();
		sound.currentTime = 0;
	} catch (error) {
		console.log(error, "Browser is preventing autoplay of music");
	}
};
