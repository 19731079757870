<script>
</script>

<div class="bubble-wrap">
	{#each [...Array(30)] as _elem}
		<div class="bubble" />
	{/each}
</div>

<style lang="scss">
	@function randomNum($min, $max) {
		$rand: random();
		$randomNum: $min + floor($rand * (($max - $min) + 1));
		@return $randomNum;
	}
	.bubble-wrap {
		margin: 0 auto;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 100%;
		transform-style: preserve-3d;
		transform-origin: center center;
		perspective: 300px;
		overflow: hidden;
	}
	.bubble {
		position: absolute;
		opacity: 0.7;
		box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
		border-radius: 50%;
		animation: move 5s ease-in-out reverse 1;
	}
	@for $i from 1 through 30 {
		.bubble:nth-child(#{$i}) {
			$size: random(50) + px;
			height: $size;
			width: $size;
			animation-delay: -$i * 0.1s;
			transform: translate3d(#{randomNum(-1000, 1000)}px, #{randomNum(-1000, 1000)}px, 0);
			background: rgba(161, 161, 161, 0.575);
		}
	}
	@keyframes move {
		100% {
			transform: translate3d(0, 0, 0);
		}
	}
</style>
