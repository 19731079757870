export const BGMUSIC_START = "BGMUSIC_START";
export const BGMUSIC_STOP = "BGMUSIC_STOP";
export const CLAPPINGS_START = "CLAPPINGS_START";
export const CLAPPINGS_STOP = "CLAPPINGS_STOP";
export const CLICK = "CLICK";

export const CLOSE_TO_ANSWER = "CLOSE_TO_ANSWER";
export const CORRECT_ANSWER = "CORRECT_ANSWER";
export const WRONG_ANSWER = "WRONG_ANSWER";

export const COUNTDOWN_START = "COUNTDOWN_START";
export const COUNTDOWN_STOP = "COUNTDOWN_STOP";
export const FAST_COUNTDOWN_START = "FAST_COUNTDOWN_START";
export const FAST_COUNTDOWN_STOP = "FAST_COUNTDOWN_STOP";

export const PROMPT_TO_CANVAS = "PROMPT_TO_CANVAS";
export const QUESTION_TO_LEADERBOARD = "QUESTION_TO_LEADERBOARD";
export const SKIPPED = "SKIPPED";

export const TIMER_TO_CANVAS_START = "TIMER_TO_CANVAS_START";
export const TIMER_TO_CANVAS_STOP = "TIMER_TO_CANVAS_STOP";
export const MUTE = "MUTE";
export const UNMUTE = "UNMUTE";
