<script>
	import { dbGameSessionPage, dbGameSessionRound } from "../services/database";

	export let word;
	export let isActive;
	export let handleClick;
	export let hoverEffect;
</script>

<div class={`word ${isActive ? "word-active" : ""} ${hoverEffect ? "word-onhover" : ""}`} on:click={handleClick}>
	{word}
</div>

<style>
	.word {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px;
		min-width: 100px;
		height: 43px;
		background: #f7efe7;
		border-radius: 4.16034px;
		color: #e27a17;
		text-transform: capitalize;
		font-family: Pally-Bold;
		font-size: 16px;
		line-height: 19px;
		display: flex;
	}
	.word-onhover:hover,
	.word-active {
		background-color: #f6963b;
		color: white;
		cursor: pointer;
	}
</style>
