<script>
	import { ProfilePicture } from "da-components";
	import { chat, usersStore } from "../services/store";
	import { onMount } from "svelte";

	let _chats = [];
	let id = 0;
	function createChat(val) {
		_chats = [{ ...val, id: id++ }, ..._chats];
	}
	onMount(() =>
		chat.subscribe(val => {
			if (val) createChat(val);
		})
	);

	function removeChat(_id) {
		_chats = _chats.filter(_c => _c.id != _id);
	}
	function spin(node, { duration }) {
		let randomRotation = Math.random() * 90 - 45;
		return {
			duration,
			css: t => {
				return `
					transform: translateY(-${t * 400}%) rotate(${randomRotation}deg);
                    opacity: ${1 - t}; 
                    `;
			},
		};
	}
</script>

{#each _chats as item (item.id)}
	<div class="chats__chat" in:spin={{ duration: 2000 }} on:introend={() => removeChat(item.id)}>
		<ProfilePicture
			src={$usersStore[item.userId]["profilePicture"]}
			userName={$usersStore[item.userId]["userName"]}
			size={25}
		/>
		<span>{$usersStore[item.userId]["userName"]}: </span>
		<span>{item.message}</span>
	</div>
{/each}

<style>
	.chats__chat {
		position: absolute;
		bottom: 50px;
		right: 10px;
		background-color: white;
		color: black;
		border-radius: 9.67175e10px;
		padding: 8px 16px;
		background: #ededed;
		box-shadow: 0px 3.14214px 0px rgb(0 0 0 / 15%);

		z-index: 1000000;
	}
</style>
