export const SPLASH_SCREEN = "SPLASH_SCREEN";
export const SPLASH_SCREEN1 = "SPLASH_SCREEN1";
export const LOBBY_SCREEN = "LOBBY_SCREEN";
export const GAME_SCREEN = "GAME_SCREEN";
export const LEADERBOARD_SCREEN = "LEADERBOARD_SCREEN";
export const TIMER_SCREEN = "TIMER_SCREEN";
export const CHOOSE_WORD_SCREEN = "CHOOSE_WORD_SCREEN";
export const LOADING_SCREEN = "LOADING_SCREEN";
export const TURN_SKIPPED_SCREEN = "TURN_SKIPPED_SCREEN";
export const CANVAS_SCREEN = "CANVAS_SCREEN";
export const END_PROMPT_SCREEN = "END_PROMPT_SCREEN";
